<template>
  <!-- customer:card START -->
  <div class="account-payback">

    <p
      v-html="`Kundenkarte hinzufügen`"
    ></p>

    <!-- form:customer:card START -->
    <el-form
      status-icon
      :rules="rules"
      ref="paybackForm"
      :model="paybackForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('paybackForm')"
    >

      <div class="is-half">
        <!-- form:customer:card:gender START -->
        <el-form-item prop="gender">
          <el-select
            v-model="paybackForm.gender"
            :placeholder="`${$t('login.gender.select')} *`"
            @keyup.prevent.native="submitForm('paybackForm')"
          >
            <el-option
              v-for="item in gender"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- form:customer:card:gender END -->

        <!-- form:customer:card:title START -->
        <el-form-item prop="titel">
          <el-input
            :placeholder="$t('login.title')"
            v-model="paybackForm.title"
            @keyup.prevent.native="submitForm('paybackForm')"
          ></el-input>
        </el-form-item>
        <!-- form:customer:card:title END -->
      </div>

      <!-- form:customer:card:first-name START -->
      <el-form-item prop="firstName">
        <el-input
          :placeholder="`${$t('login.firstname')} *`"
          v-model="paybackForm.firstName"
          @keyup.prevent.native="submitForm('paybackForm')"
        ></el-input>
      </el-form-item>
      <!-- form:customer:card:first-name END -->

      <!-- form:customer:card:last-name START -->
      <el-form-item prop="lastName">
        <el-input
          :placeholder="`${$t('login.lastname')} *`"
          v-model="paybackForm.lastName"
          @keyup.prevent.native="submitForm('paybackForm')"
        ></el-input>
      </el-form-item>
      <!-- form:customer:card:last-name END -->

      <!-- form:customer:card:number START -->
      <el-form-item prop="number">
        <span class="label" v-html="`${$t('payback.number')} *`"></span>
        <el-input
          :placeholder="'XXXX-XXXX-XXXX-XXXX'"
          v-mask="'XXXX-XXXX-XXXX-XXXX'"
          v-model="paybackForm.number"
          @keyup.prevent.native="submitForm('paybackForm')"
        ></el-input>
      </el-form-item>
      <!-- form:customer:card:number END -->

      <!-- form:customer:card:agbs START -->
      <el-form-item prop="agbs">
        <el-checkbox
          class="payback"
          v-model="paybackForm.agbs"
          @keyup.prevent.native="submitForm('paybackForm')"
        >
          {{ `${$t('account.payback.agbs')} *` }}
        </el-checkbox>
      </el-form-item>
      <!-- form:customer:card:agbs END -->

      <!-- form:customer:card:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('paybackForm')"
        >{{ $t('login.save') }}</el-button>
      </el-form-item>
      <!-- form:customer:card:submit END -->
    </el-form>
    <!-- form:customer:card END -->
  </div>
  <!-- customer:card END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'customer-card',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.customercard.headline'),

      /**
       * Form (Variables)
       */
      globalErrorMessage: false,
      gender: [
        {
          value: this.$t('login.gender.male'),
          label: this.$t('login.gender.male'),
        }, {
          value: this.$t('login.gender.female'),
          label: this.$t('login.gender.female'),
        },
      ],
      /**
       * Form (Models)
       */
      paybackForm: {
        number: '',
        gender: '',
        title: '',
        firstName: '',
        lastName: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        gender: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        firstName: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        number: [
          {
            required: true,
            min: 19,
            max: 19,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        agbs: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'change',
            ],
          },
        ],
      },
    };
  },
  methods: {
    paybackInfo() {
      this.$notify.info({
        message: this.$t('account.payback.notify.message'),
        position: 'bottom-right',
      });
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'paybackForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            formData.append('gender', this.paybackForm.gender);
            formData.append('title', this.paybackForm.title);
            formData.append('firstName', this.paybackForm.firstName);
            formData.append('lastName', this.paybackForm.lastName);
            formData.append('number', this.paybackForm.number);
            formData.append('agbs', this.paybackForm.agbs);

            // Display the key/value pairs
            [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            });

            this.$router.push('/account/customer-cards');

            return true;
          }
          this.globalErrorMessage = true;
          return false;
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },
  },
  mounted() {
    this.paybackInfo();
  },
};
</script>
